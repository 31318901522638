import React from 'react'
import PropTypes from 'prop-types'
import {
  CtaMain,
  CtaWrapper,
  CtaLink,
  ArrowMask,
  ArrowMaskHover,
} from './index.style'
import PageTransitionLink from '../PageTransitionLink'
import { TextBody } from '../TextStyles'
import Spacer from '../Spacer'
import IconArrowRight from '../svgs/IconArrowRight'
import AnimateSlideIn from '../animation/AnimateSlideIn'

const Cta = ({
  to,
  label,
  color,
  external,
  button,
  delay,
  newTab,
  animate,
  ...props
}) => (
  <CtaWrapper>
    <AnimateSlideIn delay={delay} animate={animate}>
      {external || button ? (
        <CtaLink
          as={button ? 'button' : undefined}
          href={to}
          {...props}
          target={newTab && '_blank'}
          rel={newTab && 'noopener'}
        >
          <CtaMain color={color}>
            <ArrowMaskHover>
              <IconArrowRight />
            </ArrowMaskHover>

            <TextBody>{label}</TextBody>
            <Spacer axis="horizontal" size={[7, 9]} />

            <ArrowMask>
              <IconArrowRight />
            </ArrowMask>
          </CtaMain>
        </CtaLink>
      ) : (
        <PageTransitionLink to={to} {...props}>
          <CtaMain color={color}>
            <ArrowMaskHover>
              <IconArrowRight />
            </ArrowMaskHover>
            <TextBody>{label}</TextBody>
            <Spacer axis="horizontal" size={[7, 9]} />
            <ArrowMask hoverArrow={`false`}>
              <IconArrowRight />
            </ArrowMask>
          </CtaMain>
        </PageTransitionLink>
      )}
    </AnimateSlideIn>
  </CtaWrapper>
)

Cta.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  external: PropTypes.bool,
  button: PropTypes.bool,
  onClick: PropTypes.func,
  delay: PropTypes.number,
  animate: PropTypes.bool,
}

Cta.defaultProps = {
  delay: 0,
  animate: true,
}

export default Cta
