import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ContactFormMain } from '../ContactForm/index.style'

export const ContactSocial = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`

export const ContactSocialItem = styled.li`
  :not(:first-child) {
    ${clamp('margin-left', 22, 30)}
  }
`

export const ContactSocialLink = styled.a`
  display: block;
  color: ${colors.darkGrey};
  border: none;
`

export const ContactFormWrapper = styled.div`
  ${ContactFormMain} {
    ${mq.desk} {
      grid-template-areas:
        'type type type type type type'
        'name name name email email email'
        'speciality speciality speciality speciality speciality speciality'
        'position position position position position position'
        'phone phone phone country country country'
        'message message message message message message'
        'legal legal legal legal legal legal'
        'marketing marketing marketing marketing marketing marketing'
        'submit submit submit submit submit submit';
    }
  }
`
