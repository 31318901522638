import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import Contact from '../components/Contact'

const ContactPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    title,
    addressLabel,
    address: { address },
    emailLabel,
    email,
  } = data.contentfulContact
  const contactData = {
    title,
    addressLabel,
    address,
    emailLabel,
    email,
    ...data.contentfulFooter,
  }

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.contact}
      />

      <Contact data={contactData} />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          contact
        }
      }
    }
    contentfulContact {
      seoTitle
      seoDescription {
        seoDescription
      }
      title
      addressLabel
      address {
        address
      }
      emailLabel
      email
    }
    contentfulFooter {
      madridTelephoneNumber
      miamiTelephoneNumber
      costaRicaTelephoneNumber
      franceTelephoneNumber
      instagramUrl
      facebookUrl
      youTubeUrl
      linkedInUrl
    }
    contentfulContactForm {
      title
      typeLabel
      nameLabel
      emailLabel
      phoneLabel
      countryLabel
      messageLabel
    }
  }
`

export default ContactPage
