import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  ContactFormCheckbox,
  ContactFormCheckboxLabel,
  ContactFormCheckboxMarker,
  ContactFormCheckboxRow,
  ContactFormField,
  ContactFormInput,
  ContactFormLabel,
  ContactFormMain,
  ContactFormSelect,
  ContactFormSelectLabel,
  ContactFormSelectWrapper,
  ContactFormSuccess,
  ContactFormTextarea,
} from './index.style'
import Cta from '../Cta'
import RichText from '../RichText'
import Spacer from '../Spacer'
import { TextBody } from '../TextStyles'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import { colors } from '../../styles/vars/colors.style'

const ContactForm = ({ delay }) => {
  const {
    contentfulContactForm: {
      typeLabel,
      typeOptions,
      nameLabel,
      emailLabel,
      specialityLabel,
      specialityOptions,
      positionLabel,
      phoneLabel,
      countryLabel,
      messageLabel,
      submitText,
      legalCheckboxText,
      marketingCheckboxText,
      formSubmitMessage,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulContactForm {
        typeLabel
        typeOptions
        nameLabel
        emailLabel
        specialityLabel
        specialityOptions
        positionLabel
        phoneLabel
        countryLabel
        messageLabel
        submitText
        legalCheckboxText {
          raw
        }
        marketingCheckboxText {
          raw
        }
        formSubmitMessage
      }
    }
  `)
  const formRef = useRef()
  const [formSent, setFormSent] = useState(false)
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [speciality, setSpeciality] = useState('')
  const [position, setPosition] = useState('')
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')
  const [message, setMessage] = useState('')
  const [legal, setLegal] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const formTypeOptions = []
  const formSpecialityOptions = []
  const subjectPrefix = 'Contact form submission from:'
  const typeName = 'Type of enquiry'
  const nameName = 'Name'
  const emailName = 'Email'
  const specialityName = 'Speciality'
  const positionName = 'Position'
  const phoneName = 'Phone number'
  const countryName = 'Country'
  const signupName = 'Signup to marketing'
  const messageName = 'Message'

  const countryList = [
    'Afghanistan',
    'Åland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas (the)',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia (Plurinational State of)',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory (the)',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands (the)',
    'Central African Republic (the)',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands (the)',
    'Colombia',
    'Comoros (the)',
    'Congo (the Democratic Republic of the)',
    'Congo (the)',
    'Cook Islands (the)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    "Côte d'Ivoire",
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic (the)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands (the) [Malvinas]',
    'Faroe Islands (the)',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories (the)',
    'Gabon',
    'Gambia (the)',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (the)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    "Korea (the Democratic People's Republic of)",
    'Korea (the Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic (the)",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands (the)',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia (Federated States of)',
    'Moldova (the Republic of)',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands (the)',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger (the)',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands (the)',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines (the)',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of North Macedonia',
    'Romania',
    'Russian Federation (the)',
    'Rwanda',
    'Réunion',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan (the)',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan (Province of China)',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands (the)',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates (the)',
    'United Kingdom of Great Britain and Northern Ireland (the)',
    'United States Minor Outlying Islands (the)',
    'United States of America (the)',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela (Bolivarian Republic of)',
    'Viet Nam',
    'Virgin Islands (British)',
    'Virgin Islands (U.S.)',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ]

  typeOptions.forEach(option => {
    formTypeOptions.push({
      value: option.replace(/\W+/g, '-').toLowerCase(),
      label: option,
    })
  })

  specialityOptions.forEach(option => {
    formSpecialityOptions.push({
      value: option.replace(/\W+/g, '-').toLowerCase(),
      label: option,
    })
  })

  useEffect(() => {
    // Update scroll whenever the type field value changes
    window.scroll.update()
  }, [type])

  const encode = data => {
    const value = Object.keys(data).map(
      key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    )
    return value.join('&')
  }

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      'form-name': formRef.current.getAttribute('name'),
      subject: `${subjectPrefix} ${name}`,
      [typeName]:
        formTypeOptions.find(option => option.value === type)?.label || '',
      [nameName]: name,
      [emailName]: email,
      [specialityName]:
        formSpecialityOptions.find(option => option.value === speciality)
          ?.label || '',
      [positionName]: position,
      [phoneName]: phone,
      [countryName]: country,
      [signupName]: marketing ? 'Yes' : 'No',
      [messageName]: message,
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    })
      .then(() => setFormSent(true))
      .catch(error => alert(error))
  }

  const handleLegalClick = e => {
    e.preventDefault()
    setLegal(!legal)
  }

  const handleMarketingClick = e => {
    e.preventDefault()
    setMarketing(!marketing)
  }

  return (
    <>
      <ContactFormMain
        ref={formRef}
        name="contact-form"
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <input
          type="hidden"
          name="subject"
          value={`${subjectPrefix} ${name}`}
        />

        <ContactFormField area="type">
          <AnimateSlideIn delay={delay}>
            <ContactFormSelectWrapper>
              <ContactFormSelect
                id="contact-type"
                name={typeName}
                value={type}
                onChange={e => setType(e.target.value)}
                required
              >
                <option value="" disabled>
                  {typeLabel}*
                </option>

                {React.Children.toArray(
                  formTypeOptions.map(option => (
                    <option value={option.value}>{option.label}</option>
                  ))
                )}
              </ContactFormSelect>

              <ContactFormSelectLabel show={type !== ''} htmlFor="contact-type">
                {typeLabel}
              </ContactFormSelectLabel>
            </ContactFormSelectWrapper>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="name">
          <AnimateSlideIn delay={delay + 0.1}>
            <ContactFormInput
              id="contact-name"
              name={nameName}
              type="text"
              placeholder=" "
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <ContactFormLabel htmlFor="contact-name">
              {nameLabel}
            </ContactFormLabel>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="email">
          <AnimateSlideIn delay={delay + 0.2}>
            <ContactFormInput
              id="contact-email"
              name={emailName}
              type="email"
              placeholder=" "
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <ContactFormLabel htmlFor="contact-email">
              {emailLabel}
            </ContactFormLabel>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField
          area="speciality"
          hide={!(type === 'i-m-a-dentist' || type === 'soy-un-dentista')}
          select
        >
          <AnimateSlideIn>
            <ContactFormSelect
              id="contact-speciality"
              name={specialityName}
              value={speciality}
              onChange={e => setSpeciality(e.target.value)}
            >
              <option value="" disabled>
                {specialityLabel}*
              </option>
              {React.Children.toArray(
                formSpecialityOptions.map(option => (
                  <option value={option.value}>{option.label}</option>
                ))
              )}
            </ContactFormSelect>

            <ContactFormSelectLabel
              show={speciality !== ''}
              htmlFor="contact-speciality"
            >
              {specialityLabel}
            </ContactFormSelectLabel>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField
          area="position"
          hide={
            !(
              type === 'i-m-office-staff' ||
              type === 'i-represent-a-dental-lab' ||
              type === 'soy-personal-de-oficina' ||
              type === 'represento-a-un-laboratorio-dental'
            )
          }
        >
          <AnimateSlideIn>
            <ContactFormInput
              id="contact-position"
              name={positionName}
              type="text"
              placeholder=" "
              value={position}
              onChange={e => setPosition(e.target.value)}
            />
            <ContactFormLabel htmlFor="contact-position">
              {positionLabel}
            </ContactFormLabel>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="phone">
          <AnimateSlideIn delay={delay + 0.3}>
            <ContactFormInput
              id="contact-phone"
              name={phoneName}
              type="tel"
              placeholder=" "
              value={phone}
              onChange={e => setPhone(e.target.value)}
              required
            />
            <ContactFormLabel htmlFor="contact-phone">
              {phoneLabel}
            </ContactFormLabel>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="country">
          <AnimateSlideIn delay={delay + 0.4}>
            <ContactFormSelectWrapper>
              <ContactFormSelect
                id="contact-country"
                name={countryName}
                value={country}
                onChange={e => setCountry(e.target.value)}
                required
              >
                <option value="" disabled>
                  {countryLabel}*
                </option>
                {/* <option value="Spain">Spain</option>
                <option value="USA">USA</option> */}
                {countryList.map((country, key) => {
                  return (
                    <option key={key} value={country}>
                      {country}
                    </option>
                  )
                })}
              </ContactFormSelect>
              <ContactFormSelectLabel
                show={country !== ''}
                htmlFor="contact-country"
              >
                {countryLabel}
              </ContactFormSelectLabel>
            </ContactFormSelectWrapper>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="message">
          <AnimateSlideIn delay={delay + 0.5}>
            <ContactFormTextarea
              id="contact-message"
              name={messageName}
              rows="5"
              placeholder=" "
              value={message}
              onChange={e => setMessage(e.target.value)}
              required
            />
            <ContactFormLabel htmlFor="contact-message">
              {messageLabel}
            </ContactFormLabel>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="legal">
          <Spacer size={11} />

          <AnimateSlideIn delay={delay + 0.6}>
            <ContactFormCheckboxRow>
              <ContactFormCheckbox
                type="checkbox"
                id="contact-legal"
                name="legal"
                checked={legal}
                onChange={() => setLegal(!legal)}
                required
              />

              <ContactFormCheckboxMarker
                onClick={handleLegalClick}
                aria-label="Checkbox"
              />

              <ContactFormCheckboxLabel htmlFor="contact-legal">
                <RichText content={legalCheckboxText} paragraphSize="small" />
              </ContactFormCheckboxLabel>
            </ContactFormCheckboxRow>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="marketing">
          <AnimateSlideIn delay={delay + 0.7}>
            <ContactFormCheckboxRow>
              <ContactFormCheckbox
                type="checkbox"
                id="contact-marketing"
                name={signupName}
                checked={marketing}
                onChange={() => setMarketing(!marketing)}
              />

              <ContactFormCheckboxMarker
                onClick={handleMarketingClick}
                aria-label="Checkbox"
              />

              <ContactFormCheckboxLabel htmlFor="contact-marketing">
                <RichText
                  content={marketingCheckboxText}
                  paragraphSize="small"
                />
              </ContactFormCheckboxLabel>
            </ContactFormCheckboxRow>
          </AnimateSlideIn>
        </ContactFormField>

        <ContactFormField area="submit">
          <Cta
            label={submitText}
            type="submit"
            color={colors.blue}
            delay={delay + 0.8}
            button
          />
        </ContactFormField>
      </ContactFormMain>

      <ContactFormSuccess show={formSent}>
        <TextBody>{formSubmitMessage}</TextBody>
      </ContactFormSuccess>
    </>
  )
}

ContactForm.propTypes = {
  delay: PropTypes.number,
}

ContactForm.defaultProps = {
  delay: 0,
}

export default ContactForm
