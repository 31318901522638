import React from 'react'
import PropTypes from 'prop-types'
import {
  ContactFormWrapper,
  ContactSocial,
  ContactSocialItem,
  ContactSocialLink,
} from './index.style'
import ScrollSection from '../../components/ScrollSection'
import Spacer from '../../components/Spacer'
import Grid from '../../components/Grid'
import GridItem from '../../components/GridItem'
import { Heading1, TextBody, TitleSmall } from '../../components/TextStyles'
import Container from '../../components/Container'
import AnimateSplitText from '../../components/animation/AnimateSplitText'
import { colors } from '../../styles/vars/colors.style'
import { convertLineBreaksToHTML } from '../../utils/utils'
import IconInstagram from '../svgs/IconInstagram'
import IconFacebook from '../svgs/IconFacebook'
import IconYouTube from '../svgs/IconYouTube'
import IconLinkedIn from '../svgs/IconLinkedIn'
import ContactForm from '../ContactForm'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import { animation } from '../../styles/vars/animation.style'

const Contact = ({ data }) => {
  const {
    title,
    addressLabel,
    address,
    emailLabel,
    email,
    madridTelephoneNumber,
    miamiTelephoneNumber,
    costaRicaTelephoneNumber,
    franceTelephoneNumber,
    instagramUrl,
    facebookUrl,
    youTubeUrl,
    linkedInUrl,
  } = data

  return (
    <ScrollSection>
      <Container>
        <Spacer size={[117, 274]} />

        <Grid>
          <GridItem tiny={7} tabletP={12} deskL={11} deskLStart={2}>
            <Heading1 as="h2">
              <AnimateSplitText heading>{title}</AnimateSplitText>
            </Heading1>

            <Spacer size={[56, 126]} />
          </GridItem>

          <GridItem
            tiny={6}
            tinyStart={1}
            tabletP={3}
            tabletPOrder={3}
            desk={2}
            deskOrder={3}
            deskStart={9}
            deskL={2}
            deskLOrder={3}
            deskLStart={8}
          >
            <TitleSmall color={colors.midGrey}>
              <AnimateSplitText>{addressLabel}</AnimateSplitText>
            </TitleSmall>

            <Spacer size={8} />

            <TextBody>
              <AnimateSplitText delay={0.1}>
                {convertLineBreaksToHTML(address)}
              </AnimateSplitText>
            </TextBody>

            <Spacer size={[40, 60]} />

            <TitleSmall color={colors.midGrey}>
              <AnimateSplitText delay={0.2}>{emailLabel}</AnimateSplitText>
            </TitleSmall>

            <Spacer size={8} />

            <TextBody>
              <AnimateSplitText delay={0.3}>
                <a href={`mailto:${email}`}>{email}</a>
              </AnimateSplitText>
            </TextBody>

            <Spacer size={[48, 99]} />

            <ContactSocial>
              <ContactSocialItem>
                <AnimateSlideIn delay={0.4}>
                  <ContactSocialLink
                    href={instagramUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconInstagram />
                  </ContactSocialLink>
                </AnimateSlideIn>
              </ContactSocialItem>

              <ContactSocialItem>
                <AnimateSlideIn delay={0.5}>
                  <ContactSocialLink
                    href={facebookUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconFacebook />
                  </ContactSocialLink>
                </AnimateSlideIn>
              </ContactSocialItem>

              <ContactSocialItem>
                <AnimateSlideIn delay={0.6}>
                  <ContactSocialLink
                    href={youTubeUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconYouTube />
                  </ContactSocialLink>
                </AnimateSlideIn>
              </ContactSocialItem>

              <ContactSocialItem>
                <AnimateSlideIn delay={0.7}>
                  <ContactSocialLink
                    href={linkedInUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconLinkedIn />
                  </ContactSocialLink>
                </AnimateSlideIn>
              </ContactSocialItem>
            </ContactSocial>

            <Spacer size={56} />
          </GridItem>

          <GridItem
            tiny={6}
            tinyStart={7}
            tabletP={3}
            tabletPOrder={4}
            desk={2}
            deskOrder={4}
            deskStart={11}
            deskL={2}
            deskLOrder={4}
            deskLStart={10}
          >
            <TitleSmall color={colors.midGrey}>
              <AnimateSplitText>Madrid</AnimateSplitText>
            </TitleSmall>

            <Spacer size={8} />

            <TextBody>
              <AnimateSplitText delay={0.1}>
                {madridTelephoneNumber}
              </AnimateSplitText>
            </TextBody>

            <Spacer size={[20, 30]} />

            <TitleSmall color={colors.midGrey}>
              <AnimateSplitText delay={0.2}>Miami</AnimateSplitText>
            </TitleSmall>

            <Spacer size={8} />

            <TextBody>
              <AnimateSplitText delay={0.3}>
                {miamiTelephoneNumber}
              </AnimateSplitText>
            </TextBody>

            <Spacer size={[20, 30]} />

            <TitleSmall color={colors.midGrey}>
              <AnimateSplitText delay={0.4}>Costa Rica</AnimateSplitText>
            </TitleSmall>

            <Spacer size={8} />

            <TextBody>
              <AnimateSplitText delay={0.5}>
                {costaRicaTelephoneNumber}
              </AnimateSplitText>
            </TextBody>

            <Spacer size={[20, 30]} />

            <TitleSmall color={colors.midGrey}>
              <AnimateSplitText delay={animation.maskHideDuration + 0.6}>
                France
              </AnimateSplitText>
            </TitleSmall>

            <Spacer size={8} />

            <TextBody>
              <AnimateSplitText delay={animation.maskHideDuration + 0.7}>
                {franceTelephoneNumber}
              </AnimateSplitText>
            </TextBody>
          </GridItem>

          <GridItem
            tabletP={6}
            tabletPOrder={2}
            desk={6}
            deskOrder={2}
            deskL={4}
            deskLStart={2}
            deskLOrder={2}
          >
            <ContactFormWrapper>
              <ContactForm />
            </ContactFormWrapper>
          </GridItem>
        </Grid>

        <Spacer size={[80, 234]} />
      </Container>
    </ScrollSection>
  )
}

Contact.propTypes = {
  data: PropTypes.object,
}

export default Contact
